import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './homeBottom.css';
import { Link } from 'gatsby';

const HomeBottom = (props: any) => {
  const linkTo = props.isCustomerPage ? 'customer' : 'member';
  return (
    <Container className="home-bottom-container" fluid>
      <Row className="home-bottom-action">
        <Col md={6} xs={12} className="home-bottom-action-left">
          <Link className="btn-register" to={`/${linkTo}/register`}>
            会員登録
          </Link>
        </Col>
        <Col md={6} xs={12} className="home-bottom-action-right">
          <Link className="btn-login" to={`/${linkTo}/login`}>
            {props.isCustomerPage ? '物流見積のご依頼' : '見積依頼を探す'}
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeBottom;
