import React from 'react';
import { Container, Row, Col, Label } from 'reactstrap';
import './homeNews.css';
import { BsChevronRight } from 'react-icons/all';
import { Link } from 'gatsby';

const HomeNews = (props: any) => (
  <Container className="home-news-container bg-gray" fluid>
    <Row className="home-news-label">
      <Label className="label-main text-white">NEWS</Label>
      <Label className="label-sub">お知らせ</Label>
    </Row>
    <Row className="justify-content-center">
      <Col lg={8} md={9} xs={12}>
        {props.listNews &&
          props.listNews?.map((item: any) => (
            <Row
              className="align-items-center home-news-item"
              onClick={() => props.onDetail(item.id)}
            >
              <Col xs={12} md={3} xl={2}>
                <span>
                  <b>{item.date}</b>
                </span>
              </Col>
              <Col xs={10} md={8} xl={9}>
                <span>{item.message}</span>
              </Col>
              <Col xs={2} md={1} xl={1} className="home-news-icon-next">
                <BsChevronRight />
              </Col>
            </Row>
          ))}
      </Col>
    </Row>
    {props.listNews?.length > 0 ? (
      <Row className="d-flex justify-content-center price-button">
        <Link
          to={`/history-notification/${props.role}`}
          className="d-flex justify-content-center align-items-center price-link"
        >
          もっと見る
        </Link>
      </Row>
    ) : (
      <Row className="d-flex justify-content-center price-button">
        <b className="text-center">現在お知らせがありません</b>
      </Row>
    )}
  </Container>
);

export default HomeNews;
