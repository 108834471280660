import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Label,
  FormFeedback,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Required from '../../views/util/components/Required';
import './homeContact.css';

import { sendContact, clearStatus } from '../../redux/actions/index';

const HomeContact = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [tel, setTel] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const isSuccess = useSelector(
    (state: any) => state.contact.isSuccessContact,
    shallowEqual,
  );

  const checkValidate = () => !(!name || !email || !text);
  const handleSendContact = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (checkValidate()) {
      const param = {
        name,
        email,
        text,
        tel,
      };
      dispatch(sendContact(param));
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setModalStatus(true);
    }
    if (modalStatus) {
      dispatch(clearStatus());
    }
  }, [dispatch, isSuccess, modalStatus]);
  const onClose = () => {
    setModalStatus(false);
    setName(' ');
    setEmail(' ');
    setText(' ');
    setTel(' ');
    dispatch(clearStatus());
  };
  return (
    <Container className="home-contact-container" id="contact" fluid>
      <Row className="home-contact-label">
        <Label className="label-main">CONTACT</Label>
        <Label className="label-sub">お問い合わせ</Label>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} xs={10}>
          <Form>
            <FormGroup row>
              <Label for="name" md={3}>
                姓名
                <Required type={0} />
              </Label>
              <Col md={9}>
                <Input
                  invalid={!name && isSubmit}
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <FormFeedback>姓名を入力してください。</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" md={3}>
                メールアドレス
                <Required type={0} />
              </Label>
              <Col md={9}>
                <Input
                  invalid={!email && isSubmit}
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormFeedback>メールアドレスを入力してください。</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="tel" md={3}>
                電話番号
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  name="tel"
                  id="tel"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="textarea" md={3} className="label-content">
                お問い合わせ内容
                <Required type={0} />
              </Label>
              <Col md={9}>
                <Input
                  style={{ height: 250 }}
                  invalid={!text && isSubmit}
                  value={text}
                  type="textarea"
                  name="textarea"
                  id="textarea"
                  onChange={(e) => setText(e.target.value)}
                />
                <FormFeedback>
                  お問い合わせ内容を入力してください。
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup className="text-center">
              <Button className="btn-contact" onClick={handleSendContact}>
                問い合わせる
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Modal isOpen={modalStatus}>
        <ModalBody>
          お問い合わせが完了しました。後ほど担当者からメールにて返信させていただきます。
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onClose}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default HomeContact;
