import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Button,
  CardImg,
  // UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  // DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { navigate } from '@reach/router';
import './homeHeader.css';
import { Link } from 'gatsby';
import ImgLogo from '../../images/logoLNext-edit-old-3nd.png';
import IconShipper from '../../images/assets/icon-shipper.png';
import IconCustomer from '../../images/assets/icon-customer.png';

import Matching from '../../images/img_matching.png';

const HomeHeader = (props: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const nf = new Intl.NumberFormat();
  const DropdownMenuOpen = () => {
    if (dropdownMenuOpen) {
      setDropdownMenuOpen(false);
    }
  };
  return (
    <Container
      className="home-header-container"
      fluid
      onClick={() => DropdownMenuOpen()}
    >
      <Row className="home-header-nav">
        <Col md={4} xs={6} className="header-logo">
          <CardImg src={ImgLogo} alt="logo" className="home-header-logo" />
        </Col>
        <Col md={8} xs={7} className="home-header-menu">
          <Navbar
            light
            expand="lg"
            className={`home-header-navbar-light ${dropdownOpen ? 'collapse-open' : ''
              }`}
          >
            <NavbarToggler
              onClick={() => navigate(
                `/${props.isCustomerPage ? 'customer' : 'member'}#contact`,
              )}
              className="btn-contact-mobile order-2"
            />
            {
              props.isLoggedIn ? (
                <Collapse isOpen className="customer-navbar-collapse order-1" navbar>
                  <Nav navbar>
                    {/* {
                  !props.isCustomerPage && (
                    <>
                      <NavItem>
                        <NavLink href="/">
                          見積依頼をしたい方はこちら
                        </NavLink>
                      </NavItem>会員登録はこちら
                      <NavItem className="dash-item">
                        |
                      </NavItem>
                    </>
                  )
                } */}
                    {props.isCustomerPage ? (
                      <NavItem>
                        <NavLink href="/customer/delivery_request/post">
                          会員メニュー
                        </NavLink>
                      </NavItem>
                    ) : (
                      <NavItem>
                        <NavLink href="/member/delivery_request">
                          会員メニュー
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        href={`/${props.isCustomerPage ? '' : 'member'}#contact`}
                      >
                        <span className="btn btn-contact">お問い合わせ</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              ) : (
                <>
                  <NavbarToggler onClick={toggle} className="order-3" />
                  <Collapse isOpen={dropdownOpen} className="order-3" navbar>
                    <Nav navbar>
                      {/* {
                            !props.isCustomerPage && (
                              <>
                                <NavItem>
                                  <NavLink href="/">
                                    見積依頼をしたい方はこちら
                                  </NavLink>
                                </NavItem>会員登録はこちら
                                <NavItem className="dash-item">
                                  |
                                </NavItem>
                              </>
                            )
                      } */}
                      {props.isCustomerPage ? (
                        <NavItem>
                          <NavLink href="/customer/login">会員の方はこちら</NavLink>
                        </NavItem>
                      ) : (
                        <NavItem>
                          <NavLink href="/member/login">会員の方はこちら</NavLink>
                        </NavItem>
                      )}
                      {/* <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        ログイン
                      </DropdownToggle>
                      <DropdownMenu className="bg-red">
                        <DropdownItem className="dropdown-item">
                          <NavLink href="/driver/login">
                            <Row className="menu-item-title">
                              荷物運びたい
                            </Row>
                            <Row>
                              <Col xs={3}>
                                <CardImg
                                  src={IconShipper}
                                  alt="icon-shipper"
                                  className="icon-shipper"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row className="menu-item-content">
                                  ドライバー/配送会社でログイン&emsp;〉
                                </Row>
                                <Row className="menu-item-content">
                                  荷物を運びたい方はこちら
                                </Row>
                              </Col>
                            </Row>
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink href="/customer/login">
                            <Row className="menu-item-title">
                              荷物を送りたい
                            </Row>
                            <Row>
                              <Col xs={3}>
                                <CardImg
                                  src={IconCustomer}
                                  alt="icon-shipper"
                                  className="icon-shipper"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row className="menu-item-content">荷主でログイン&emsp;〉</Row>
                                <Row className="menu-item-content">
                                  配送をご利用の方はこちら
                                </Row>
                              </Col>
                            </Row>
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                      <NavItem className="dash-item">|</NavItem>
                      <NavItem id="signup">
                        {props.isCustomerPage && (
                          <NavLink
                            href={`/${props.isCustomerPage ? 'customer' : 'member'}/register`}
                          >
                            {/* {props.isCustomerPage ? '案件を探す' : '従業員の方はこちら'} */}
                            会員登録はこちら
                          </NavLink>
                        )}
                        {!props.isCustomerPage && (
                          <>
                            <NavLink
                              className="notDisabled"
                              onClick={() => setDropdownMenuOpen(true)}
                              onFocus={() => setDropdownMenuOpen(true)}
                              onMouseLeave={() => setDropdownMenuOpen(true)}
                              onMouseOver={() => setDropdownMenuOpen(true)}
                            >
                              <UncontrolledDropdown>
                                会員登録はこちら
                                <DropdownMenu className={`banner ${dropdownMenuOpen ? 'bg-red dropdown-menu-member notDisabled' : 'bg-red notDisabled'}`} isOpen>
                                  <DropdownItem className="dropdown-item">
                                    <NavLink href="/member/register">
                                      <Row>
                                        <Col xs={2} className="icon-member">
                                          <CardImg
                                            src={IconShipper}
                                            alt="icon-shipper"
                                            className="icon-shipper"
                                          />
                                        </Col>
                                        <Col xs={9}>
                                          <Row className="menu-item-content-link">
                                            <Col xs={10} className="title-content-link">
                                              一般利用者登録
                                            </Col>
                                            <Col xs={2}>
                                              <span className="icon-right-link">&emsp;〉</span>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </NavLink>
                                  </DropdownItem>
                                  <div className="border-link-member">{' '}</div>
                                  <DropdownItem className="menu-item-member-manager">
                                    <NavLink href="/member/register_manager">
                                      <Row>
                                        <Col xs={2} className="icon-member">
                                          <CardImg
                                            src={IconCustomer}
                                            alt="icon-shipper"
                                            className="icon-shipper"
                                          />
                                        </Col>
                                        <Col xs={9}>
                                          <Row className="menu-item-content-link">
                                            <Col xs={10} className="title-content-link">
                                              代表者登録
                                            </Col>
                                            <Col xs={2}>
                                              <span className="icon-right-link">&emsp;〉</span>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </NavLink>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </NavLink>
                          </>
                        )}
                      </NavItem>


                      <NavItem>
                        <NavLink
                          href={`/${props.isCustomerPage ? '' : 'member'}#contact`}
                        >
                          <span className="btn btn-contact">お問い合わせ</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </>
              )
            }
          </Navbar>
        </Col>
      </Row>
      <Row className="align-items-center section-panel-car" onMouseOver={() => { setDropdownMenuOpen(false); }}>
        {props.isCustomerPage ? (
          <Col md={6} xs={12} className="panel-car-left">
            <Row className="grid-container">
              <h1 className="text-on-car col-12 col-md-12">
                <span className="text-above text-above-delivery row">
                  <span>配送・保管・荷物</span>
                </span>
                <span className="text-above row">
                  <span>
                    に関する悩みを全て解決!
                  </span>
                </span>
                <span className="text-above row">
                  <span>プロに気軽に相談できる!</span>
                </span>
                <span className="row">
                  <span className="l-next-text">「Contact」</span>
                </span>
              </h1>
            </Row>
          </Col>
        ) : (
          <Col md={6} xs={12} className="panel-car-left">
            <Row className="grid-container">
              <h1 className="text-on-car col-12 col-md-12">
                <span className="text-above text-above-delivery row">
                  <span>これまでお取引のない</span>
                </span>
                <span className="text-above row">
                  <span>
                    新規のお客様との
                  </span>
                </span>
                <span className="text-above row">
                  <span>商談の場をご提供します！</span>
                </span>
                <span className="row">
                  <span className="l-next-text">「Contact」</span>
                </span>
              </h1>
            </Row>
          </Col>
        )}
        <Col md={6} xs={12} className="panel-car-right">
          <Row>
            <Col md={12}>
              {props.isCustomerPage ? (
                <>
                  <Row>
                    <span className="text-above">
                      配送・保管等の業務内容を入力して待つだけ。
                      <br />
                      物流のプロからオファーが届きます。
                    </span>
                  </Row>
                  <Row className="row-below">
                    <div className="text-below">
                      <span className="content-skew">荷主企業様はご利用無料!</span>
                    </div>
                  </Row>
                </>
              ) : (
                <>
                  {/* <Row>
                    <span className="text-above">
                      新たな商談の機会に。好きな案件を自由な提案で。
                    </span>
                  </Row>
                  <Row>
                    <span className="text-middle">
                      空き時間、空きトラックの有効活用や
                    </span>
                  </Row>
                  <Row>
                    <span className="text-middle">
                      継続的なビジネスパートナーへ繋げる場としてもご利用いただけます。
                    </span>
                  </Row> */}
                  <Row className="row-below">
                    <div className="text-below">
                      <span className="content-skew">
                        ３月末までご利用無料!
                      </span>
                    </div>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="row-button">
        <Col lg={6} md={12} xs={12} className="button-action">
          <Link
            to={`/${props.isCustomerPage ? 'customer' : 'member'}/register`}
            className="btn-register"
          >
            会員登録
          </Link>
          <Link
            to={`/${props.isCustomerPage ? 'customer' : 'member'}#flow`}
            className="btn-flow"
          >
            ご利用の流れ
          </Link>
        </Col>
        { props.numberMatchingRequest > 99 && (
        <Col lg={6} md={12} xs={12} className="matching-information">
          <Row>
            <Col lg={5} md={5} xs={5} className="matching-information-image">
              <img src={Matching} alt="feature-02" />
            </Col>
            <Col lg={7} md={7} xs={7}>
              <Row>
                <Col className="matching-information-counter">{nf.format(Math.trunc(props.numberMatchingRequest / 100) * 100)}</Col>
                <Col className="matching-information-posttext">
                  <Row>
                    <Col xs={12} className="matching-information-posttext_s">
                      件
                      <span> 突破!!</span>
                    </Col>
                    <Col xs={12} className="matching-information-posttext_date">
                      ※2021年10月時点
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        )}
      </Row>
    </Container>
  );
};

export default HomeHeader;
